export const idsMonths = [
  [0, 31],
  [31, 59],
  [59, 90],
  [90, 120],
  [120, 151],
  [151, 181],
  [181, 212],
  [212, 243],
  [243, 273],
  [273, 304],
  [304, 334],
  [334, 365],
];
export const daysIsNotPost = [
  [0, 7],
  // [0, 12],
  // [0, 14],
  [0, 19],
  [1, 16],
  [1, 18],
];
export const daysIsPost = [
  [0, 18],
  [8, 11],
  [8, 27],
];
export const monthSerb = [
  "januar",
  "februar",
  "mart",
  "april",
  "maj",
  "jun",
  "jul",
  "avgust",
  "septembar",
  "oktobar",
  "novembar",
  "decembar",
];
export const redDaysId = [
  7, 8, 9, 14, 19, 20, 27, 46, 153, 154, 163, 164, 165, 188, 193, 214, 231, 240,
  254, 264, 270, 300, 304, 312, 325, 338, 353,
];
export const manualDateEaster = [
  "4-19",
  "5-2",
  "4-24",
  "4-16",
  "5-5",
  "4-20",
  "4-12",
  "5-2",
  "4-16",
  "4-8",
  "4-28",
  "4-13",
  "5-2",
];
export const easterDays = [
  "Veliki četvrtak (Veliko bdenije)",
  "Veliki petak",
  "Velika subota",
  "V a s k r s – Vaskrsenje Gospoda Isusa Hrista",
];
export const calendarYears = [
  {
    item_list: [
      { title: 2022, route: "/crkveni-kalendar/2022" },
      { title: 2023, route: "/crkveni-kalendar/2023" },
      { title: 2024, route: "/crkveni-kalendar/2024" },
      { title: 2025, route: "/crkveni-kalendar/2025" },
      { title: 2026, route: "/crkveni-kalendar/2026" },
      { title: 2027, route: "/crkveni-kalendar/2027" },
      { title: 2028, route: "/crkveni-kalendar/2028" },
    ],
  },
];
export const spisakNedelja = [
  "Седмица прва – Светла",
  "Седмица друга – Мироносица",
  "Седмица трећа – Раслабљеног",
  "Седмица четврта – Самарјанке",
  "Седмица пета – Слепога",
  "Седмица шеста – Светих Отаца Првог васељенског сабора",
  "Седмица шеста – Светих Отаца Првог васељенског сабора",
  "Седмица Педесетнице",
];
export const details = [
  { title: "Ime i prezime", name: "name" },
  { title: "Kontakt telefon", name: "tel" },
  { title: "Vaša poruka", name: "text" },
];
export const tretmanColors = [
  { tretman: "manastir", color: "#940922" },
  { tretman: "crkva", color: "#b29411" },
  // { tretman: "nega lica", color: "#F7A072" },
  // { tretman: "nega tela", color: "#55C1FF" },
  { tretman: "ostalo", color: "#5F1A37" },
];
export const options = [
  //0
  {
    route: "/",
    title: "Ikone.rs",
    social: {
      title: "Ikone.rs",
      lead: "Ikone.rs - Ikone, crkveni kalendar, manastiri...",
      pics: "/img/test3.jpg",
    },
  },
  //1
  { route: "/manastiri", title: "Manastiri" },
  //2
  {
    route: "/ikone",
    title: "Ikone",
  },
  //3
  {
    route: "/crkveni-kalendar",
    title: "Crkveni kalendar",
    social: {
      title: "Crkveni kalendar",
      lead: "Crkveni pravoslavni kalendar | Svi praznici, slave i posti u godini na jednom mestu. Srpske crkve i manastiri, posni recepti, molitve | Crkveni kalendar",
      pics: "/img/test3.jpg",
    },
    social2: {
      title: "Crkveni kalendar",
      lead: " | Svi praznici, slave i posti u godini na jednom mestu. Srpske crkve i manastiri, posni recepti, molitve | Crkveni kalendar ",
      pics: "/img/test3.jpg",
    },
  },
  //4
  {
    route: "/ostalo/meseceve-mene",
    title: "Mesečeve mene",
    social: {
      title: "Mesečeve mene",
      lead: "Mesečeve mene i lunarne faze u 2023. godini. MESEČEV KALENDAR. Precizan kalendar faza meseca za kompletnu godinu. Prikaz mesečevih mena za svaki mesec...",
      pics: ["/img/article_img/moon.jpg", "Pexels"],
    },
  },
  // { route: "/molitve", title: "Molitve" },
  // { route: "/slave", title: "Slave" },
  // { route: "/ostalo", title: "Ostalo" },
];

export const footer = [
  "O nama",
  "Marketing",
  "Pravila korišćenja",
  "Politika privatnosti",
];
